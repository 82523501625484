<template>
  <section class="basic-textarea">
    <b-row>
      <b-col cols="12">
        <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
          <b-card>
            <b-row>
              <b-col cols="12" md="8" lg="5" xl="3">
                <b-form-group :label="$t('order.date_range')" label-for="date_input">
                  <flat-pickr id="date_input" v-model="date" class="form-control" @on-change="changeDate" :config="pickConfig" />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="overflow-auto">
                <b-overlay :show="!tableReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
                  <table id="ordersList" class="dataTable table">
                    <thead>
                      <tr>
                        <th>{{ $t('order.table.date') }}</th>
                        <th class="text-right">{{ $t('order.table.no') }}</th>
                        <th class="text-center" v-show="isMasterCompany">
                          {{ $t('order.table.erp_id') }}
                        </th>
                        <th class="text-right">
                          {{ $t('order.table.c_code') }}
                        </th>
                        <th class="company-name">
                          {{ $t('order.table.c_name') }}
                        </th>
                        <th class="text-center">
                          {{ $t('shop.payment_type') }}
                        </th>
                        <th class="text-center">
                          {{ $t('order.table.state') }}
                        </th>
                        <th class="text-right">
                          {{ $t('order.table.amount') }}
                        </th>
                        <th></th>
                        <th v-if="$can('manage', '/Shop/OrderDetails')"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in tableData" :key="index">
                        <td class="text-left text-nowrap" :data-order="orderDate(data.order_date)">
                          {{ showDate(data.order_date) }}
                        </td>
                        <td class="text-right text-nowrap">
                          {{ data.order_no }}
                        </td>
                        <td class="text-center" v-show="isMasterCompany">
                          <span v-if="data.erp_description" v-b-tooltip.hover.bottom.v-primary :title="data.erp_description" class="cursor-pointer">
                            <b-badge :variant="data.erp_master_id ? 'light-success' : 'light-danger'">
                              {{ data.erp_master_id ? data.erp_master_id : $t('order.table.no_erp') }}
                            </b-badge>
                          </span>
                          <b-badge :variant="data.erp_master_id ? 'light-success' : 'light-danger'" v-else>
                            {{ data.erp_master_id ? data.erp_master_id : $t('order.table.no_erp') }}
                          </b-badge>
                        </td>
                        <td class="text-right text-nowrap">
                          {{ data.company.company_code }}
                        </td>
                        <td class="text-left company-name">
                          {{ data.company.company_name }}
                        </td>
                        <td class="text-center">
                          <b-badge :variant="data.payment_type == 1 ? 'light-warning' : data.payment_type == 2 ? 'light-success' : 'light-danger'">
                            {{ $t('shop.payment_type_' + data.payment_type) }}
                          </b-badge>
                        </td>
                        <td class="text-center">
                          <b-badge :variant="data.order_state == 1 ? 'light-warning' : data.order_state == 2 ? 'light-success' : data.order_state == 3 ? 'light-danger' : 'light-info'">
                            {{ $t('order.' + data.order_state) }}
                          </b-badge>
                        </td>
                        <td class="text-right text-nowrap" :data-order="data.amt_total">
                          {{ priceFormat(data.amt_total) }}
                        </td>
                        <td class="text-center">
                          <span v-if="data.order_state_desc" v-b-tooltip.hover.bottom.v-primary :title="data.order_state_desc">
                            <i class="fal fa-info cursor-pointer" />
                          </span>
                        </td>
                        <td class="text-center" v-if="$can('manage', '/Shop/OrderDetails')">
                          <b-link
                            :to="{
                              name: '/Shop/OrderDetail',
                              params: { id: data.order_m_id }
                            }"
                            target="_blank"
                          >
                            <i class="fal fa-receipt"></i>
                          </b-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
      <!-- <b-table :items="$ability.j"></b-table> -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BDropdown, BDropdownItem, BLink, BOverlay, BFormGroup, BTable, BBadge, VBTooltip } from 'bootstrap-vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import { english } from 'flatpickr/dist/l10n/default.js'
import '@/assets/js/functions/datatables.settings'
import $ from 'jquery'
import { mapGetters } from 'vuex'
import { GET_ORDERS } from '@/store/services/order-service'
import { convertTL, priceFormat } from '@/assets/js/functions/currency-functions'
export default {
  name: 'orders',
  components: {
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BLink,
    BOverlay,
    BFormGroup,
    BTable,
    BBadge,
    flatPickr
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      tableData: [],
      table: null,
      tableReady: true,
      date: `${moment()
        .subtract(7, 'days')
        .toISOString()} - ${moment().toISOString()}`,
      ready: false,
      pickConfig: {
        mode: 'range',
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'd.m.Y',
        locale: { ...Turkish, rangeSeparator: ' - ' }
      }
    }
  },
  computed: {
    ...mapGetters(['getLocale', 'isMasterCompany'])
  },
  created() {
    this.getOrders()
    this.changeFlatLocale()
  },
  methods: {
    createTable() {
      this.tableReady = false
      this.table = $('#ordersList').DataTable({
        columnDefs: [
          { orderable: false, targets: [7, 8] },
          { type: 'num', targets: [0, 1, 3, 6] }
        ],
        scrollX: true,
        scrollY: '1000px',
        scrollCollapse: true,
        order: [0, 'desc'],
        buttons: [
          {
            extend: 'colvis',
            text: this.$t('dataTable.show_hide')
          },
          {
            extend: 'pdf'
          },
          {
            extend: 'excel',
            title: this.$t('Orders'),
            filename: this.$t('Orders')
          }
        ],
        language: {
          url: this.getLocale == 'tr-TR' ? '//cdn.datatables.net/plug-ins/1.11.1/i18n/tr.json' : '//cdn.datatables.net/plug-ins/1.11.1/i18n/en-gb.json'
        }
      })
      this.tableReady = true
    },
    changeDate(date) {
      if (date.length === 2) {
        this.getOrders()
      }
    },
    getOrders() {
      this.ready = false
      if (this.table != null) {
        this.table.destroy()
      }
      let temp = this.date.split(' - ')
      this.$store
        .dispatch(GET_ORDERS, {
          startDate: moment(temp[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(temp[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
        })
        .then(response => {
          this.tableData = response
        })
        .then(() => {
          this.createTable()
        })
        .then(() => {
          this.ready = true
        })
    },
    showDate(date) {
      return new Intl.DateTimeFormat(this.getLocale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(new Date(date))
    },
    orderDate(date) {
      return moment(date).format('X') * 1
    },
    priceFormat(price) {
      return priceFormat(price, this.getLocale, 'TRY')
    },
    changeFlatLocale() {
      switch (this.getLocale) {
        case 'tr-TR':
          this.pickConfig = {
            mode: 'range',
            dateFormat: 'Z',
            altInput: true,
            altFormat: 'd.m.Y',
            locale: { ...Turkish, rangeSeparator: ' - ' }
          }
          break
        default:
          this.pickConfig = {
            mode: 'range',
            dateFormat: 'Z',
            altInput: true,
            altFormat: 'm/d/Y',
            locale: { ...english, rangeSeparator: ' - ' }
          }
          break
      }
    }
  },
  watch: {
    getLocale(val) {
      Promise.all([this.table.destroy()]).then(this.createTable)
      this.changeFlatLocale()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.company-name {
  max-width: 250px;
}
</style>
